import type { User } from '@iheartradio/web.config';

export const getNewRelicCustomAttributes = ({
  appVersion,
  requestInfo,
  SHORT_COMMIT,
  user,
}: {
  appVersion?: string;
  requestInfo?: {
    isMobile?: boolean;
    hints?: {
      theme?: string;
    };
  };
  SHORT_COMMIT?: string;
  user?: User;
}) => {
  return {
    accountType: user?.accountType ?? '',
    appVersion: appVersion ?? '',
    isAnonymous: user?.isAnonymous ?? '',
    isMobile: requestInfo?.isMobile ?? '',
    profileId: user?.profileId ? String(user.profileId) : '',
    shortCommit: SHORT_COMMIT ?? '',
    theme: requestInfo?.hints?.theme ?? '',
  };
};
