declare global {
  interface Window {
    newrelic?: typeof import('newrelic');
  }
}

import { isUndefined } from '@iheartradio/web.utilities';

import type { ErrorNotifierProps } from './register-notifiers.js';

export const newRelicNotifier = ({
  error,
  customAttributes,
}: ErrorNotifierProps) => {
  if (isUndefined(globalThis?.window)) {
    return;
  }

  if (isUndefined(globalThis?.window?.newrelic)) {
    console.log('New Relic Browser Agent Not Initialized');
    return;
  }

  const newRelicError = error.errorSource.payload as Error;

  const newRelicAttributes = customAttributes ?? {};

  globalThis?.window.newrelic.noticeError(newRelicError, newRelicAttributes);
};
